import React from 'react';

const RawIcon = props =>
    <svg version="1.1" id="Capa_1"
         x="0px" y="0px"
         width="24px"
         height="24px"
         viewBox="0 0 550.801 550.801"
    >
        <path d="M488.426,197.019H475.2v-63.816c0-0.398-0.063-0.799-0.116-1.202c-0.021-2.534-0.827-5.023-2.562-6.995L366.325,3.694
		         c-0.032-0.031-0.063-0.042-0.085-0.076c-0.633-0.707-1.371-1.295-2.151-1.804c-0.231-0.155-0.464-0.285-0.706-0.419
		         c-0.676-0.369-1.393-0.675-2.131-0.896c-0.2-0.056-0.38-0.138-0.58-0.19C359.87,0.119,359.037,0,358.193,0H97.2
		         c-11.918,0-21.6,9.693-21.6,21.601v175.413H62.377c-17.049,0-30.873,13.818-30.873,30.873v160.545
                 c0,17.043,13.824,30.87,30.873,30.87h13.224V529.2c0,11.907,9.682,21.601,21.6,21.601h356.4c11.907,0,21.6-9.693,21.6-21.601
                 V419.302h13.226c17.044,0,30.871-13.827,30.871-30.87v-160.54C519.297,210.838,505.47,197.019,488.426,197.019z M97.2,21.605
                 h250.193v110.513c0,5.967,4.841,10.8,10.8,10.8h95.407v54.108H97.2V21.605z M268.3,235.544l44.41,143.185h-34.836l-11.048-36.756
                 h-41.009l-10.188,36.756h-33.565l43.753-143.185H268.3z M96.654,322.429v56.289H64.589V237.455
                 c10.413-1.7,25.906-2.979,43.116-2.979c21.241,0,36.115,3.196,46.298,11.265c8.506,6.797,13.176,16.782,13.176,29.945
                 c0,18.267-12.954,30.817-25.275,35.273v0.628c9.967,4.039,15.496,13.605,19.122,26.768c4.454,16.147,8.92,34.837,11.672,40.363
                 H139.56c-2.339-4.04-5.74-15.726-9.985-33.36c-3.829-17.834-9.985-22.723-23.15-22.929H96.654z M453.601,523.353H97.2V419.302
                 h356.4V523.353z M454.207,378.729h-35.053l-12.118-61.615c-2.753-14.438-5.095-27.822-6.787-44.176h-0.427
                 c-2.548,16.143-4.884,29.737-8.279,44.176l-13.601,61.615h-35.475l-33.992-143.185h34.614l10.843,59.052
                 c3.185,16.996,6.159,35.479,8.49,49.929h0.433c2.336-15.504,5.737-32.716,9.344-50.34l12.113-58.636h34.404l11.48,60.323
                 c3.18,16.79,5.516,32.083,7.646,48.02h0.427c2.125-15.937,5.311-32.716,8.274-49.707l11.686-58.63h32.928L454.207,378.729z"/>
        <path d="M253.229,287.382c-2.561-8.501-5.086-19.124-7.22-27.62h-0.427c-2.133,8.501-4.245,19.33-6.6,27.62l-8.488,30.375h31.649
		         L253.229,287.382z"/>
        <path d="M135.098,278.459c0-13.179-8.915-19.76-23.78-19.971c-7.868,0-12.324,0.638-14.663,1.062v39.517h12.751
		         C125.545,299.067,135.098,290.988,135.098,278.459z"/>
    </svg>;

export default RawIcon;